
























































import { Component, Vue } from "vue-property-decorator";
import paciente_conf_emailModule from "@/store/modules/paciente_conf_email-module";
import { UtilsString } from "@/utils/utils-string";
import { paciente } from "@/shared/dtos/paciente-dto";
import pacienteModule from "@/store/modules/paciente-module";
import { UtilsEncript } from "@/utils/utils-encript";
import { UtilsNotify } from "@/utils/utils-notify";
@Component({
  $_veeValidate: { validator: "new" },
  components: {
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
    PacienteFichaOpciones: () =>
      import("@/views/pacientes/paciente-ficha-opciones.vue"),
    InformacionBasicaPaciente: () =>
      import(
        "@/views/pacientes/información_paciente/informacion_basica_paciente.vue"
      ),
  },
})
export default class paciente_marketing_mail extends Vue {
  public id_paciente: number = 0;
  created() {
    if (UtilsString.IsValidParamUrl(this.$route.query.id_paciente)) {
      this.id_paciente = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id_paciente)
      );
      pacienteModule.getpaciente(
        new paciente({
          numero: this.id_paciente,
        })
      );
      paciente_conf_emailModule.getpaciente_conf_emails_activados(
        this.id_paciente
      );
    }
  }
  resetear() {
    paciente_conf_emailModule.getpaciente_conf_emails_activados(
      this.paciente.numero
    );
  }
  aceptar() {
    paciente_conf_emailModule
      .modificarpaciente_conf_emails(this.emails_marketing)
      .then(() => {
        UtilsNotify.NotificacionSuccess(
          "Correos marketing",
          "Su configuración se ha guardado correctamente"
        );
      });
  }
  public get tiene_email() {
    return !UtilsString.IsNullOrWhiteSpace(
      UtilsString.ValueOf(this.paciente.email)
    );
  }
  public get paciente() {
    return pacienteModule.paciente;
  }

  public get emails_marketing() {
    return paciente_conf_emailModule.paciente_conf_emails;
  }
}
